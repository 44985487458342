import moment from 'moment/moment';

export default {
  install(Vue, options) {
    /***********************************************************************************************
        系統配置
    ************************************************************************************************/

    //Regin API地址
    Vue.prototype.$ServerUrl = function() {
      //return 'https://localhost:44341';
			//return 'http://47.100.207.178:8085';
			return window.config.apiPath;
    };
    //Endregin API地址


    //Regin 保存登录凭证
    Vue.prototype.$SaveKey = function(obj, key) {
      if (key == null || typeof(key) == "undefined" || key == "") {
        localStorage.setItem('currentUser', JSON.stringify(obj));
      } else {
        localStorage.setItem(key, JSON.stringify(obj));
      }
      console.log(localStorage.getItem("currentUser"));
    };
    //Endregin 保存登录凭证

    //Regin 获取登录凭证
    Vue.prototype.$GetKey = function(key) {
      if (key == null || typeof(key) == "undefined" || key == "") {
        return localStorage.getItem('currentUser');
      } else {
        return localStorage.getItem(key);
      }
    };
    //Endregin 获取登录凭证

    //注销时,清空所有缓存和凭证
    Vue.prototype.$RemoveKey = function() {
      localStorage.clear();
      sessionStorage.clear();
    }


    /*Regin
      保存临时信息
      @objSessionName: 名称
      @objSessionValue: 值
    */
    Vue.prototype.$SaveSession = function(objSessionName, objSessionValue) {
      sessionStorage.setItem(objSessionName, JSON.stringify(objSessionValue));
    };
    //Endregin 保存临时信息

    /*Regin
      获取临时信息
      @objSessionName: 名称
    */
    Vue.prototype.$GetSession = function(objSessionName) {
      return JSON.parse(sessionStorage.getItem(objSessionName));
    };
    //Endregin 获取临时信息

    /*Regin
      删除临时信息
      @objSessionName: 名称为空时，删除所有
    */
    Vue.prototype.$RemoveSession = function(objSessionName) {
      if (this.$Verify_Null(objSessionName)) {
        sessionStorage.removeItem(objSessionName);
      } else {
        sessionStorage.clear();
      }
    };
    //Endregin 删除临时信息

  }
};

//判断权限
//key是页面中的权限变量
//code可以action对应的代码，也可以是action对应的url
window.isPower = function(that, key, code, callback) {
  var powJudge = JSON.parse(that.$GetKey("actions"));
  var power = powJudge;
  if (
    (power.filter(item => item.url != null && item.url.toUpperCase().trim() == code.toUpperCase().trim()).length >= 1) ||
    (power.filter(item => item.code != null && item.code.toUpperCase().trim() == code.toUpperCase().trim()).length >=
      1)
  ) {
    that.isPower[key] = true;
  }
}
