import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './plugins/element.js';

import ElementUI from 'element-ui';     //装入Element
import 'element-ui/lib/theme-chalk/index.css';
/*
// AdminLTE-3.0.5 模板样式
import './assets/css/adminlte.css';
// 全局通用样式
import './assets/css/tools.css';
import './assets/css/style.css';
// 字体
import './assets/font/font.css';
// 阿里图标库
import './assets/font/iconfont.css';
import './assets/font/iconfont.js';
*/
//API
import axios from 'axios';
import qs from 'qs';


/*
富文本框
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
*/

// 表单验证组件
import verify from "vue-verify-plugin";

// jquery
//import $ from 'jquery';
import md5 from 'js-md5';
// 全局方法调用
import imCommon from './plugins/Common.js';
import imMethod from './plugins/method.js';
//import preventReClick from './plugins/preventReClick'
// 网站标题
import VueWechatTitle from 'vue-wechat-title';

import moment from 'moment'       //moment.js
Vue.prototype.$moment = moment;


//定义一个全局过滤器实现日期格式化
Vue.filter('dateformat', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
Vue.filter('dateformatMinute', function(dataStr, pattern = 'YYYY-MM-DD HH:mm') {
  return moment(dataStr).format(pattern)
})

Vue.filter('dateformatDay', function(dataStr, pattern = 'YYYY-MM-DD') {
  return moment(dataStr).format(pattern)
})

// 全局方法调用
Vue.use(imCommon);
// 网站标题
Vue.use(VueWechatTitle);
// 表单验证组件
Vue.use(verify);

// jquery
//Vue.prototype.$ = $;

//全局注册，使用方法为:this.$axios
Vue.prototype.$axios = axios;
//全局注册，使用方法为:this.qs
Vue.prototype.$qs = qs;

Vue.config.productionTip = false;

Vue.prototype.$md5 = md5;




new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
