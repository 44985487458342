<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"/>
  </div>
</template>

<script>
export default {
  methods: {
  },
	created() 
	{
	},
  mounted () 
	{
    /*if (this._isMobile()) {
      console.log('移动端');
      //this.$router.replace('/mobile/login')
    } else {
      console.log('pc端');
      this.$router.replace('/Student/Login');
    }*/
    //this.$router.replace('/Admin/Login');
  },
	watch: {
		$route() 
		{
			let that = this;
			var accessToken = that.$GetKey(); //sessionStorage.getItem('accessToken');
			if (accessToken != null && typeof(accessToken) != "undefined" && accessToken != "" && accessToken != "undefined") {
				var url = that.$route.fullPath.toLowerCase();
				var isManager = JSON.parse(that.$GetKey("isManager"));
				if(isManager == true && url.indexOf("/admin")<0)
				{
					that.$router.push('/Admin/home/index');
				}
				else if(isManager == false && url.indexOf("/student")<0)
				{
					that.$router.push('/student/home/index');
				}
			}
		}
	}
	

};

</script>

<style>

</style>
