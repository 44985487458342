//询问提示框
window.confirm = function(that, msg, callback) { //  判断是否确认删除
  that.$confirm(msg, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    callback(true);
  }).catch(() => {
    that.$message({
      type: 'info',
      message: '已取消'
    });
  });
}

// 删除确认弹出框
window.confirmDelete = function(that, msg, callback) {
  if (msg == null || typeof(msg) == "undefined" || msg == "") {
    msg = "您确定要删除选中的信息吗？ 删除后信息将不可恢复，请知悉";
  }
  confirm(that, msg, callback);
}

//以警告的样式显示提示语
window.warning = function(that, msg) {
  that.$message({
    message: msg,
    type: "warning"
  });
  if(msg.indexOf("缺省访问令牌")>=0
    || msg.indexOf("无效的访问令牌")>=0
    || msg.indexOf("登录失效")>=0
    || msg.indexOf("登录超时，请重新登录！")>=0
  )
  {

    setTimeout(function(){
      localStorage.clear();
      sessionStorage.clear();
      that.$router.replace('/login?url=' + that.$router.history.current.fullPath);
    },500);

  }
}

// 以成功的样式提示语
window.success = function(that, msg) {
  that.$message({
    message: msg,
    type: "success"
  });
}

//接口返回接口验证是否成功
window.Authentication = function(that, res) {
  return true;
  if (res.data.code != 200) {
    warning(that, res.data.message);
    that.fullscreenLoading = false;
    if (res.data.message.indexOf("登录") >= 0) {
			localStorage.clear();
			sessionStorage.clear();
      var fullPath = decodeURI(that.$router.history.current.fullPath).toLowerCase();
      setTimeout(function() {
      	if(fullPath.indexOf("login?url=")<0)
      	{
      		that.$router.push({
      			path: '/login',
      			query: {
      				url: that.$router.history.current.fullPath
      			},
      		});
      	}
      	else{
      		that.$router.push({
      		  path: that.$router.history.current.fullPath,
      		});
      	}
        return false;
      }, 1500);
      return false;
    }
    return false;
  }
}

//前端登录的token是否存在
window.IsToken = function(that) {
  var accessToken = localStorage.getItem('currentUser');
  if (accessToken == null || typeof(accessToken) == "undefined" || accessToken == "" || accessToken == "undefined") {
		localStorage.clear();
		sessionStorage.clear();
    warning(that, "登录失效，请重新登陆！");
		var fullPath = decodeURI(that.$router.history.current.fullPath).toLowerCase();
    setTimeout(function() {
			if(fullPath.indexOf("login?url=")<0)
			{
				that.$router.push({
					path: '/login',
					query: {
						url: that.$router.history.current.fullPath
					},
				});
			}
			else{

				that.$router.push({
				  path: that.$router.history.current.fullPath,
				});
			}
      return false;
    }, 1500);
    return false;
  }
  var t = JSON.parse(accessToken);
  return t.token;
}


// 以get方式访问接口，查询
window.ajaxGet = function(that, myUrl, data, callback) {
  if (myUrl != null && typeof(myUrl) != "undefined" && myUrl != "") {
    let clienturl = that.$route.path;
    var getUrl = that.$ServerUrl() + myUrl;
    var token = IsToken(that);
    var currentUser = JSON.parse(localStorage.getItem("currentUser"));
    that.$axios.get(
      getUrl, {
        params: data,
        headers: {
          'Authorization': "Bearer " + token,
          "Content-Type":"application/json;charset=utf-8",
          "Access-Control-Allow-Origin":"*",
          "Access-Control-Expose-Headers":"X-Requested-With",
          "Access-Control-Allow-Methods":"GET",
          "token":currentUser.token,
        }
      }
    ).then(res => {
      //Authentication(that, res);
      if (res.data.success == true) callback(res.data.data);
      else {
        that.fullscreenLoading = false;
        warning(that, res.data.message);
      }
    }).catch(err => {
      that.fullscreenLoading = false;
      console.log(myUrl);
      warning(that, "网络出现问题无法访问接口，请联系相关人员。");
    });
  } else {
    that.fullscreenLoading = false;
    callback(null);
  }
};

//以get方式导出文件
//fileName要全称，包括扩展名
window.ajaxExport = function(that, myUrl, data, fileName, callback) {
  if (myUrl != null && typeof(myUrl) != "undefined" && myUrl != "") {
    let clienturl = that.$route.path;
    var getUrl = that.$ServerUrl() + myUrl;
    var token = IsToken(that);
    that.$axios.get(
      getUrl, {
        params: data,
        headers: {
          'Authorization': "Bearer " + token,
        },
        'responseType': "blob"
      },

    ).then(res => {
      // 处理返回的文件流
      //主要是将返回的data数据通过blob保存成文件
      var content = res.data;
      var blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        var elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        callback(res.data.data);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    }).catch(err => {
      console.log(err);
      that.fullscreenLoading = false;
      warning(that, "网络出现问题无法访问接口，请联系相关人员。");
    });
  } else {
    callback(null);
  }
};

// 以post方式访问接口，提交
window.ajaxPost = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  var currentUser = JSON.parse(localStorage.getItem("currentUser"));
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
        "Content-Type":"application/json;charset=utf-8",
        "Access-Control-Allow-Origin":"*",
        "Access-Control-Expose-Headers":"X-Requested-With",
        "Access-Control-Allow-Methods":"Post",
        "token":currentUser.token,
      }
    }
  ).then(res => {
    console.log(res);
    Authentication(that, res);
		if (res.data.success == true) {
			if (res.data.data.result == true)
			{
				var reg = new RegExp(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/);
				if (!reg.test(res.data.data.message)) {
					success(that, res.data.data.message);
				}
				else{
					success(that,"新增成功");
				}
				callback(res.data.data);
			}
			else{
				that.fullscreenLoading = false;
				warning(that, res.data.data.message);
			}
		}
		else{
			that.fullscreenLoading = false;
			warning(that, res.data.message);
			if(res.data.message.indexOf("参数验证错误")>=0)
			{
				console.log(res.data.data);
			}
		}
  }).catch(err => {
    console.log(err);
    that.fullscreenLoading = false;
    warning(that, "网络出现问题无法访问接口，请联系相关人员。");
  });
};

// 无提示交互数据
window.ajaxPostForNoTip = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      }
    }
  ).then(res => {
    Authentication(that, res);
		if (res.data.success == true) {
			if (res.data.data.result == true)
			{
				var reg = new RegExp(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/);
				if (!reg.test(res.data.data.message)) {
					//success(that, res.data.data.message);
          console.log(res.data.data.message);
				}
				else{
          console.log('新增成功');
					//success(that,"新增成功");
				}
				callback(res.data.data);
			}
			else{
				//warning(that, res.data.data.message);
        console.log(res.data.data.message);
			}
		}
		else{
			//warning(that, res.data.message);
			if(res.data.message.indexOf("参数验证错误")>=0)
			{
				console.log(res.data.data);
			}
		}
  }).catch(err => {
    console.log(err);
    //warning(that, "网络出现问题无法访问接口，请联系相关人员。");
  });
};

//上传
window.ajaxUpload = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data,
		{
      headers: {
        'Authorization': "Bearer " + token,
      },
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  ).then(res => {

    Authentication(that, res);
		if (res.data.data.result == true)
		{
			 success(that, res.data.data.message);
			 callback(res.data.data);
		}
		else{
			that.fullscreenLoading = false;
			warning(that, res.data.data.message);
		}
  }).catch(err => {
    that.fullscreenLoading = false;
    warning(that, "网络出现问题无法访问接口，请联系相关人员。");
  });
};

window.ajaxImport = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
  that.$axios.post(
    url,
    data, {
      headers: {
        'Authorization': "Bearer " + token,
      },
      'Content-Type': 'multipart/form-data'
    }
  ).then(res => {
    Authentication(that, res);
		if (res.data.data.result == true)
		{
			 success(that, res.data.data.message);
			 callback(res.data.data);
		}
		else{
			that.fullscreenLoading = false;
			warning(that, res.data.data.message);
			callback(res.data.data);
		}
  }).catch(err => {
    console.log(err);
    that.fullscreenLoading = false;
    warning(that, "网络出现问题无法访问接口，请联系相关人员。");
  });
};


// 系統登录
window.ajaxLogin = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
	/*that.axios.userLogin(url,data).then(res=>{
	     console.log("sd")
	 }).catch(function (error) {
	     console.log(error);
	 });
*/
  that.$axios.post(
    url,
    data,
  ).then(res => {
    callback(res);
  }).catch(err => {
    console.log(err);
    that.fullscreenLoading = false;
    warning(that, "网络出现问题无法访问接口，请联系相关人员。");
  });
};

//以put方式访问接口，修改
window.ajaxPut = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
      var currentUser = JSON.parse(localStorage.getItem("currentUser"));
  var param = JSON.stringify(data);
  that.$axios.post(
    url,
    data,
		{
      headers: {
        'Authorization': "Bearer " + token,
        "Content-Type":"application/json;charset=utf-8",
        "Access-Control-Allow-Origin":"*",
        "Access-Control-Expose-Headers":"X-Requested-With",
        "Access-Control-Allow-Methods":"Post",
        "token":currentUser.token,
      }
    }
  ).then(res => {
    Authentication(that, res);
		if (res.data.success == true) {
			if (res.data.data.result == true)
			{
				 var reg = new RegExp(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/);
				 if (!reg.test(res.data.data.message)) {
				 	success(that, res.data.data.message);
				 }
				 else{
				 	success(that,"编辑成功");
				 }
				 callback(res.data.data);
			}
			else{
				that.fullscreenLoading = false;
				warning(that, res.data.data.message);
			}
		}
		else{
			that.fullscreenLoading = false;
			warning(that, res.data.message);
			if(res.data.message.indexOf("参数验证错误")>=0)
			{
				console.log(res.data.data);
			}
		}
  }).catch(err => {
    console.log(err);
    that.fullscreenLoading = false;
    warning(that, "网络出现问题无法访问接口，请联系相关人员。");
  });
};

//以delete方式访问接口,删除
window.ajaxDelete = function(that, myUrl, data, callback) {
  let clienturl = that.$route.path;
  var url = that.$ServerUrl() + myUrl;
  var token = IsToken(that);
    var currentUser = JSON.parse(localStorage.getItem("currentUser"));
  that.$axios.post(
    url, {
      data,
      headers: {
        'Authorization': "Bearer " + token,
        "Content-Type":"application/json;charset=utf-8",
        "Access-Control-Allow-Origin":"*",
        "Access-Control-Expose-Headers":"X-Requested-With",
        "Access-Control-Allow-Methods":"Post",
        "token":currentUser.token,
      }
    }
  ).then(res => {
    Authentication(that, res);
    if (res.data.success == true) {
      if (res.data.data.result == true)
      {
      	 success(that, res.data.data.message);
      	 callback(res.data.data);
      }
      else{
				that.fullscreenLoading = false;
      	warning(that, res.data.data.message);
      }
    } else warning(that, res.data.message);
  }).catch(err => {
    console.log(err);
    that.fullscreenLoading = false;
    warning(that, "网络出现问题无法访问接口，请联系相关人员。");
  });
};

window.routerPath = function(that, path, need, val, ...values) { // 路由地址 要传的建 要传的值  报保存的值
  let arr = [];
  if (typeof need == "string") { // 如果是单个值就对应传入
    arr[need] = val;
  } else if (typeof need == "object" && need != null) { //  否则传个对应对象给他    其余的值自己传入
    for (const key in need) {
      if (Array.isArray(need[key])) {
        let getData = need[key][0] + "," + need[key][0];
        need[key] = getData;
      }
    }
    arr = need;
  }
  values.forEach(element => {
    if (that.$route.query[element]) {
      if (need != null && typeof(need) == "string" && need.toLowerCase() == "pagesize" && element.toLowerCase() ==
        "pagenumer") {
        arr["PageNumer"] = 1;
      } else {
        arr[element] = that.$route.query[element];
      }
    }
  });
  that.$router.push({
    path: path,
    query: arr
  }); // 路由跳转变值
};


window.paging = function(that, callback, ...value) { // 根据路由取值
  let arr = []
  if (JSON.stringify(that.$route.query) == '{}') {
    var PageNumer = null
    var PageSize = null
    arr.PageNumer = PageNumer;
    arr.PageSize = PageSize;
    callback(arr);
  } else {
    if (that.$route.query.PageNumer == undefined) { //  判断 防止页码和页数没有值
      var PageNumer = 1;
      if (that.paging.PageNumer != undefined) {
        that.paging.PageNumer = PageNumer
      }
    } else {

      var PageNumer = that.$route.query.PageNumer;
      if (that.paging.PageNumer != undefined) {
        that.paging.PageNumer = PageNumer;
      }

    }
    if (that.$route.query.PageSize == undefined) {

      var PageSize = 10
      if (that.paging.PageSize != undefined) {
        that.paging.PageSize = 10
      }

    } else {

      var PageSize = that.$route.query.PageSize
      if (that.paging.PageSize != undefined) {
        that.paging.PageSize = PageSize * 1
      }

    }

    arr.PageNumer = PageNumer;
    arr.PageSize = PageSize;

    value.forEach(element => {
      let router = that.$route.query[element];
      if (!router && router != 0) {
        router = null
      }
      arr[element] = router
    });
    callback(arr);

  }
}

// 返回
window.back = function(that) {
  var url = that.$route.query.url;
  if (url == null || typeof(url) == "undefined" || url == "") {
    window.history.go(-1);
  } else {
    that.$router.push(url);
  }

}

window.setInt = function(val) {
  var first = "";
  if (val == null || typeof(val) == "undefined") {
    val = "";
  }
  if (val != "") {
    first = val[0];
  }
  val = val.toString();
  val = val.replace(/[^\d.]/g, "");
  val = val.replace(/\.{1,}/g, "");
  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  val = val.replace(/^(\-)*(\d+)\.(\d\d\d\d\d).*$/, '$1$2.$3'); //最多输入5个小数

  if (val.indexOf(".") < 0 && val != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    val = parseInt(val);
  }
  if (first == "-") {
    val = 0 - val;
  }
  return val;
}
window.setTwoFloat = function(val) {
  var first = "";
  if (val == null || typeof(val) == "undefined") {
    val = "";
  }
  if (val != "") {
    first = val[0];
  }

  val = val.toString();
  val = val.replace(/[^\d.]/g, "");
  val = val.replace(/\.{2,}/g, ".");
  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //最多输入5个小数
  if (val.indexOf(".") < 0 && val != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    val = parseFloat(val);
  }

  if (first == "-") {
    val = 0 - val;
  }

  return val;
}

//设置两位小数，自动补齐 默认保留2位小数点
window.setTwoDecimal = function(val, _iFixed) { // val:需要转换的值 ,_iFixed需要保留的小数点位数(默认2位)

  if (val == null || isNaN(val) || val == '') {
    return '';
  }
  if (parseFloat(val + "") == 0) {
    return val;
  }
  _iFixed = setInt(_iFixed);
  if (_iFixed == null || isNaN(_iFixed) || _iFixed == '') {
    _iFixed = 2;
  }
  return parseFloat(val).toFixed(_iFixed);
  //return moneySwitch(parseFloat(val),2);
};


window.moneySwitch = function(money, precision) { //precision是需要精确的位数，如百分位就是2
  var result = 0;
  //先进行一个千分位的四舍五入，保证3.0999这种情况在保留一位小数的时候能是对的，这一位可以这么做没什么问题
  var money = parseFloat(money).toFixed(3);
  try {
    var int_part = money.split(".")[0], //小数点前的整数
      point_num = money.split(".")[1], //取小数点后面的小数
      precision_num = point_num[3 - precision];
    if (precision_num > 4) { //五入的情况
      if (precision == 1) {
        point_num = parseInt(point_num) + 10 + "";
        if (point_num.length > 3) { //说明往整数位进1
          int_part = parseInt(int_part) + 1 + "";
          point_num = point_num[1] + point_num[2];
        } else {
          point_num = point_num[0] + point_num[1];
        }
        result = parseFloat(int_part + "." + point_num);
      } else if (precision == 2) {
        point_num = parseInt(point_num) + 100 + "";
        if (point_num.length > 3) { //说明往整数位进1
          int_part = parseInt(int_part) + 1 + "";
          point_num = point_num[1];
        } else {
          point_num = point_num[0];
        }
        result = parseFloat(int_part + "." + point_num);
      } else if (precision == 3) {
        int_part = parseInt(int_part) + 1 + "";
        point_num = 0;
      }
      result = parseFloat(int_part + "." + point_num);
    } else { //四舍的情况
      if (precision == 1) {
        point_num = point_num[0] + point_num[1];
      } else if (precision == 2) {
        point_num = point_num[0];
      } else if (precision == 3) {
        point_num = 0;
      }
      result = parseFloat(int_part + "." + point_num);
    }
  } catch (e) {
    return parseFloat(money).toFixed(2); //如果过程中有出错就tofixed代替为解决
  }
  return result.toFixed(2);
};


//去除数组内的重复
window.onArrUnique = function(arr) {
  const res = new Map();
  return arr.filter((arr) => !res.has(arr.value) && res.set(arr.value, 1));
}

	/**
	 * 数据转换为树形（递归），示例：toTreeByRecursion(source, 'id', 'parentId', null, 'children')
	 * @param {Array} source 数据
	 * @param {String} idField 标识字段名称
	 * @param {String} parentIdField 父标识字段名称
	 * @param {Any} parentIdNoneValue 父级标识空值
	 * @param {String} childrenField 子节点字段名称
	 * @param {Object} treeOption tree树形配置
	 */
	window.toTreeByRecursion =function(
	  source = [],
	  idField = 'id',
	  parentIdField = 'parentId',
	  parentIdNoneValue = '',
	  childrenField = 'children',
	  treeOption = undefined
	) {
	  const treeOptions = {
	    enable: false, // 是否开启转tree插件数据
	    keyField: 'key', // 标识字段名称，默认为key
	    valueField: 'value', // 值字段名称，默认为value
	    titleField: 'title', // 标题字段名称，默认为title

	    keyFieldBind: 'id', // 标识字段绑定字段名称，默认为id
	    valueFieldBind: 'id', // 值字段名称绑定字段名称，默认为id
	    titleFieldBind: 'name' // 标题字段名称绑定字段名称，默认为name
	  }
	  // 合并tree树形配置
	  if (treeOption) {
	    Object.assign(treeOptions, treeOption)
	  }

	  // 对源数据深度克隆
	  const cloneData = JSON.parse(JSON.stringify(source))
	  return cloneData.filter(parent => {
	    // 返回每一项的子级数组
	    const branchArr = cloneData.filter(child => parent[idField] === child[parentIdField])

	    // 绑定tree树形配置
	    if (treeOptions.enable) {
	      branchArr.map(child => {
	        child[treeOptions.keyField] = child[treeOptions.keyFieldBind]
	        child[treeOptions.valueField] = child[treeOptions.valueFieldBind]
	        child[treeOptions.titleField] = child[treeOptions.titleFieldBind]
	        return child
	      })
	    }

	    // 如果存在子级，则给父级添加一个children属性，并赋值，否则赋值为空数组
	    if (branchArr.length > 0) {
	      parent[childrenField] = branchArr
	    } else {
	      parent[childrenField] = []
	    }

	    // 绑定tree树形配置
	    if (treeOptions.enable) {
	      parent[treeOptions.keyField] = parent[treeOptions.keyFieldBind]
	      parent[treeOptions.valueField] = parent[treeOptions.valueFieldBind]
	      parent[treeOptions.titleField] = parent[treeOptions.titleFieldBind]
	    }

	    return parent[parentIdField] === parentIdNoneValue; // 返回第一层
	  })
	}

	window.checkTime = function(i)
	{
		var result = i + '';
		if (i < 10)
		{
		  result = '0' + i;
		}
		return result;
	}


	let moment = require("moment");
	window.timeTransform = function(time,format)
	{
		if(time === "")
		{
			return "";
		}
		if(format == null || format == "")
		{
			format = "YYYY-MM-DDTHH:mm:ss.SSS";
		}
		var result = moment(time).format(format);
		return result;
	}

	window.compareDate = function(start,end)
	{
		return moment(start).isBefore(end);
	}


	window.Join = function(data)
	{
		if(data!=null)
		{
			if(Array.isArray(data))
			{
				if(data.length>0)
				{
					var val = "";
					for(var it of data)
					{
						if(val == "")
						{
							val = it;
						}
						else{
							val += "," + it;
						}
					}
					return val;
				}
			}
			return data;

		}
	}
