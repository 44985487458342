import Vue from 'vue';
import Router from 'vue-router';

//重写路由，避免出现这种错误：Navigating to current location ("/manage/user") is not allowed
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};
Vue.use(Router);


export default new Router({
  //mode: 'history',
  //hashbang: false, history: true,
  routes: [
	    //设置路由
     { path: '/', redirect: '/login' },
     {
        path: '/Login',
        name: 'Admin_Login',
        component: () => import('../views/Login.vue'),
        meta: { title: "IOS分发管理系统" }
      },
      {
          path: '/Admin/Default',
          name: 'Admin_Default',
          component: () => import('../views/Admin/Default.vue'),
          meta: { title: "IOS分发管理系统" },
          redirect:'/Admin/Home/Index',// 默认起始页
          children:[
            {
                path: '/Admin/User/Index',
                name: 'Admin_User_Index',
                component: () => import('../views/Admin/User/Index.vue'),
                meta: { title: "IOS分发管理系统 - 用户管理" },
            },
						{
						    path: '/Admin/User/Create',
						    name: 'Admin_User_Create',
						    component: () => import('../views/Admin/User/Update.vue'),
						    meta: { title: "IOS分发管理系统 - 用户管理" },
						},
						{
						    path: '/Admin/User/Edit/:Id',
						    name: 'Admin_User_Edit',
						    component: () => import('../views/Admin/User/Update.vue'),
						    meta: { title: "IOS分发管理系统 - 用户管理" },
						},
						{
						    path: '/Admin/User/Interface/:Id',
						    name: 'Admin_User_Interface',
						    component: () => import('../views/Admin/User/Interface.vue'),
						    meta: { title: "IOS分发管理系统 - 用户管理" },
						},
						{
						    path: '/Admin/Interface/Index',
						    name: 'Admin_Interface_Index',
						    component: () => import('../views/Admin/Interface/Index.vue'),
						    meta: { title: "IOS分发管理系统 - 接口管理" },
						},
						{
						    path: '/Admin/Interface/Create',
						    name: 'Admin_Interface_Create',
						    component: () => import('../views/Admin/Interface/Update.vue'),
						    meta: { title: "IOS分发管理系统 - 用户管理" },
						},
						{
						    path: '/Admin/Interface/Edit/:Id',
						    name: 'Admin_Interface_Edit',
						    component: () => import('../views/Admin/Interface/Update.vue'),
						    meta: { title: "IOS分发管理系统 - 用户管理" },
						},
          ]
      }
  ]
});
